html {
  box-sizing: border-box;

  --color-fairy: #a4ead4;
  --color-fairy-contrast: #f51ea1;
  --spacing-s: 42px;
  --spacing-m: 64px;
  --spacing-l: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

::selection {
  background: var(--color-fairy);
}

html {
  font-size: 20px;
}

body {
  margin: 0;
  background-color: var(--page-background);
  display: flex;
  min-height: 100vh;
  line-height: 1.5222;
  flex-direction: column;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  letter-spacing: 0.8px;
  /* test this font? */
  font-family: "Handjet";
}

.container {
  max-width: 860px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
}

.container--wide {
  margin: 0 auto;

  margin-top: var(--spacing-s);
  margin-bottom: var(--spacing-s);

  max-width: 860px;

  padding-left: 24px;
  padding-right: 24px;
  border-top: 1px solid rbga(255, 255, 255, 0.8);
  border-bottom: 1px solid rbga(255, 255, 255, 0.8);

  border: 10px dotted #e187d9;
  border-radius: 20px;
  padding: calc(var(--spacing-s) / 2);

  margin-left: 24px;
  margin-right: 24px;
}

@media (min-width: 600px) {
  .container--wide {
    margin-left: auto;
    margin-right: auto;
    padding: var(--spacing-s);
  }
}

.container--wide > p {
  font-style: italic;
}

.flowers {
  height: auto;
  height: 500px;
  margin-top: -5%;
  transition: filter 0.1s linear;
  position: absolute;
  width: 100%;
  right: -10%;
  left: 0;
  z-index: -1;
  object-fit: cover;
}

.header {
  margin-top: 111px;
  text-align: left;
}

p i {
  font-size: 88%;
}

p {
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 0;
}

@media (min-width: 600px) {
  p {
    font-size: 28px;
  }
}

p + p {
  margin-top: var(--spacing-s);
}

h1 {
  margin-top: 10px;
  margin-bottom: calc(var(--spacing-s) - 4px);
}

.fairy {
  font-size: 48px;
}

ul,
li {
  font-size: inherit;
}

a {
  text-decoration: none;
  color: inherit;
  text-decoration: underline;
  text-decoration-style: wavy;
  text-decoration-color: var(--color-fairy);
}

a:hover {
  color: var(--color-fairy);
}

button {
  margin-top: var(--spacing-m);
  margin-bottom: var(--spacing-m);
  cursor: pointer;
  background-color: var(--color-fairy);
  border: 1px solid var(--color-fairy);
  border-radius: 25px;
  border: 0;
  padding: 18px;
  font-size: 20px;
  font-weight: 700;
  font-family: "Times New Roman", Times, serif;
  color: var(--color-fairy-contrast);
  width: 100%;
  box-shadow: var(--color-fairy) 0 -25px 18px -14px inset,
    var(--color-fairy) 0 1px 2px, var(--color-fairy) 0 2px 4px,
    var(--color-fairy) 0 4px 8px, var(--color-fairy) 0 8px 16px,
    var(--color-fairy) 0 16px 32px;

  transition: all 100ms ease-in-out;
}

@media (min-width: 600px) {
  button {
    max-width: 300px;
  }
}

button:hover {
  transform: scale(1.018);
  text-decoration: underline;
  text-decoration-style: wavy;
}

footer ul,
footer li {
  list-style-type: none;
  padding-left: 0;
}

footer {
  border-top: 1px dotted var(--color-fairy);
  font-size: inherit;
  margin-top: var(--spacing-m);
}

.footer-list {
  display: flex;
  flex-wrap: wrap;
}

.footer-list ul {
  list-style-type: none;
}

.footer-list li {
  padding-right: 20px;
}

.list {
  margin-top: 42px;
  margin-bottom: 42px;
  font-size: 24px;
}

@media (min-width: 600px) {
  .list {
    font-size: 28px;
  }
}

.list li {
  padding-top: 12px;
}

.footer-list li span {
  font-size: 80%;
}

strong {
  font-weight: 500;
}

.heart {
  position: absolute;
  font-size: 2rem;
  opacity: 1;
  transition: opacity 3s, transform 3s;
  pointer-events: none;
  user-select: none;
}

.p5Canvas {
  position: fixed;
  top: 0;
  z-index: -1;
}
