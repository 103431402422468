html {
  box-sizing: border-box;
  --color-fairy: #a4ead4;
  --color-fairy-contrast: #f51ea1;
  --spacing-s: 42px;
  --spacing-m: 64px;
  --spacing-l: 0;
}

*, :before, :after {
  box-sizing: inherit;
}

::selection {
  background: var(--color-fairy);
}

html {
  font-size: 20px;
}

body {
  background-color: var(--page-background);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  letter-spacing: .8px;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  font-family: Handjet;
  line-height: 1.5222;
  display: flex;
}

.container {
  max-width: 860px;
  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px;
}

.container--wide {
  margin: 0 auto;
  margin-top: var(--spacing-s);
  margin-bottom: var(--spacing-s);
  border-top: 1px solid rbga(255, 255, 255, .8);
  border-bottom: 1px solid rbga(255, 255, 255, .8);
  padding-left: 24px;
  padding-right: 24px;
  padding: calc(var(--spacing-s) / 2);
  border: 10px dotted #e187d9;
  border-radius: 20px;
  max-width: 860px;
  margin-left: 24px;
  margin-right: 24px;
}

@media (width >= 600px) {
  .container--wide {
    padding: var(--spacing-s);
    margin-left: auto;
    margin-right: auto;
  }
}

.container--wide > p {
  font-style: italic;
}

.flowers {
  z-index: -1;
  object-fit: cover;
  width: 100%;
  height: 500px;
  margin-top: -5%;
  transition: filter .1s linear;
  position: absolute;
  left: 0;
  right: -10%;
}

.header {
  text-align: left;
  margin-top: 111px;
}

p i {
  font-size: 88%;
}

p {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
}

@media (width >= 600px) {
  p {
    font-size: 28px;
  }
}

p + p {
  margin-top: var(--spacing-s);
}

h1 {
  margin-top: 10px;
  margin-bottom: calc(var(--spacing-s)  - 4px);
}

.fairy {
  font-size: 48px;
}

ul, li {
  font-size: inherit;
}

a {
  color: inherit;
  text-decoration: underline wavy;
  -webkit-text-decoration-color: var(--color-fairy);
  text-decoration-color: var(--color-fairy);
}

a:hover {
  color: var(--color-fairy);
}

button {
  margin-top: var(--spacing-m);
  margin-bottom: var(--spacing-m);
  cursor: pointer;
  background-color: var(--color-fairy);
  border: 1px solid var(--color-fairy);
  color: var(--color-fairy-contrast);
  box-shadow: var(--color-fairy) 0 -25px 18px -14px inset, var(--color-fairy) 0 1px 2px, var(--color-fairy) 0 2px 4px, var(--color-fairy) 0 4px 8px, var(--color-fairy) 0 8px 16px, var(--color-fairy) 0 16px 32px;
  border: 0;
  border-radius: 25px;
  width: 100%;
  padding: 18px;
  font-family: Times New Roman, Times, serif;
  font-size: 20px;
  font-weight: 700;
  transition: all .1s ease-in-out;
}

@media (width >= 600px) {
  button {
    max-width: 300px;
  }
}

button:hover {
  text-decoration: underline wavy;
  transform: scale(1.018);
}

footer ul, footer li {
  padding-left: 0;
  list-style-type: none;
}

footer {
  border-top: 1px dotted var(--color-fairy);
  font-size: inherit;
  margin-top: var(--spacing-m);
}

.footer-list {
  flex-wrap: wrap;
  display: flex;
}

.footer-list ul {
  list-style-type: none;
}

.footer-list li {
  padding-right: 20px;
}

.list {
  margin-top: 42px;
  margin-bottom: 42px;
  font-size: 24px;
}

@media (width >= 600px) {
  .list {
    font-size: 28px;
  }
}

.list li {
  padding-top: 12px;
}

.footer-list li span {
  font-size: 80%;
}

strong {
  font-weight: 500;
}

.heart {
  opacity: 1;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  font-size: 2rem;
  transition: opacity 3s, transform 3s;
  position: absolute;
}

.p5Canvas {
  z-index: -1;
  position: fixed;
  top: 0;
}
/*# sourceMappingURL=index.f074fb66.css.map */
